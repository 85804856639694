<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col>
        <el-breadcrumb>
          <el-breadcrumb-item class="pointer" :to="{ path: '/supplierOrder' }">导航</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li class="menu-item text-cut" v-for="(item, i) in tabs" :key="item"
                :class="seeThis == i ? 'active' : ''">
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip class="item" effect="dark" content="打印订单" placement="bottom">
                          <el-button v-print="printObj" type="primary" size="small">打印</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="返回列表" placement="bottom">
                          <el-button @click="goIndex()" size="small">返回</el-button>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="supplierOrder"
                  ref="supplierOrder"
                  label-width="150px"
                  class="supplierOrder"
                  size="mini"
                >
                  <el-row class="printContent">
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="supplierOrder.id"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="供应商名称:" prop="供应商名称">
                        <span>{{ supplierOrder.supplierName }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="用户名称:" prop="用户名称">
                        <span>{{ supplierOrder.memberName }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="订单号:" prop="订单号">
                        <span>{{ supplierOrder.orderNumber }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="订单金额:" prop="订单金额">
                        <span>{{ supplierOrder.orderAmount }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="支付状态:" prop="支付状态">
                        <span v-if="supplierOrder.payStatus === '1'"
                          >已支付</span
                        >
                        <span v-if="supplierOrder.payStatus === '0'"
                          >未支付</span
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="订单状态:" prop="订单状态">
                        <span v-if="supplierOrder.orderStatus === '1'"
                          >已支付</span
                        >
                        <span v-if="supplierOrder.orderStatus === '2'"
                          >已发货</span
                        >
                        <span v-if="supplierOrder.orderStatus === '3'"
                          >已完成</span
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="配送点名称:" prop="配送点名称">
                        <span>{{ supplierOrder.deliveryName }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="创建时间:" prop="createDt">
                        <span>{{ supplierOrder.createDt | dataFormat }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="微信单号:" prop="backNo">
                        <span>{{ supplierOrder.backNo }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="收货人:" prop="receiver">
                        <span>{{ supplierOrder.receiver }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="收货人电话:" prop="收货人电话">
                        <span>{{ supplierOrder.receiverPhone }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="收货人地址:" prop="receiverAddress">
                        <span>{{ supplierOrder.receiverAddress }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div style="margin-left: 500px">订单详情</div>
                
                <el-table
                  :data="tableData"
                  border
                  fit
                  :height="height"
                  highlight-current-row
                  style="width: 100%"
                  @sort-change="sortChange"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                    width="50"
                  />
                  <el-table-column
                    label="商品名称"
                    align="center"
                    prop="商品名称"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.productName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="商品图片"
                    align="center"
                    prop="商品图片"
                  >
                  
                    <template slot-scope="scope">
            <el-image
              v-if="scope.row.productImg"
              style="width: 50px; height: 50px"
              :src="scope.row.productImg"
              fit="cover"
            ></el-image>
          </template>
                  </el-table-column>

                  <el-table-column
                    label="购买数量"
                    align="center"
                    prop="购买数量"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.countNum }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="单位" align="center" prop="单位">
                    <template slot-scope="scope">
                      <span>{{ scope.row.unit }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="总价" align="center" prop="总价">
                    <template slot-scope="scope">
                      <span>{{ scope.row.totalPrice+' 元' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="创建时间"
                    align="center"
                    prop="创建时间"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.createDt | dataFormat }}</span>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="print">
                  <div class="print_container">
                    <h1>智慧社区小程序订单</h1>
                    <span>**************************</span>
                    <div class="section1">
                      <h3>智慧社区</h3>
                      <h3>在线支付</h3>
                    </div>
                    <span>**************************</span>
                    <div class="section2">
                      <label>供应商：{{supplierOrder.supplierName}}</label>
                      <!-- <label>用户名：{{supplierOrder.memberName}}</label> -->
                      <label>订单号：{{supplierOrder.orderNumber}}</label>
                      <label>订单金额：{{supplierOrder.orderAmount}}</label>
                      <label>支付状态：{{supplierOrder.payStatus=='1'?'已支付':'未支付'}}</label>
                      <label>订单状态：{{supplierOrder.orderStatus=='1'?'已支付':(supplierOrder.orderStatus == '2'?'已发货':'已完成')}}</label>
                      <label><b>配送点：{{supplierOrder.deliveryName}}</b></label>
                      <span>**************************</span>
                      <label>订单详情：</label>
                      <div v-for="(item,index) in tableData" :key="item.id">
                        <label>序号：{{index+1}}</label>
                        <label>商品：{{item.productName}}</label>
                        <label>数量：{{item.countNum}}</label>
                        <label>单位：{{item.unit}}</label>
                        <label>总价：{{item.totalPrice}}</label>
                      </div>
                    </div>
                    <span>**************************</span>
                    <div class="section3">
                      <label>创建时间：{{supplierOrder.createDt | dataFormat}}</label>
                      <!-- <label>微信单号：{{supplierOrder.backNo}}</label> -->
                    </div>
                    <span>**************************</span>
                    <div class="section5">
                      <label><b>收货人：{{supplierOrder.receiver}}</b></label>
                      <label><b>电话：{{supplierOrder.receiverPhone}}</b></label>
                      <label><b>地址：{{supplierOrder.receiverAddress}}</b></label>
                    </div>
                    <span>**************************</span>
                  </div>
                </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",

      tabs: ["基本信息"],
      supplierOrder: {
        id: "",
        supplierCode: "",
        supplierName: "",
        memberCode: "",
        memberName: "",
        orderNumber: "",
        orderAmount: "",
        payStatus: "",
        orderStatus: "",
        deliveryCode: "",
        deliveryName: "",
        longitude: "",
        latitude: "",
        createDt: "",
        backNo: "",
        backDt: "",
        receiver: "",
        receiverPhone: "",
        receiverAddress: "",
        receiverLongitude: "",
        receiverLatitude: "",
      },
      printObj: {
        id: "print",
        popTitle: "支付订单",
        // extraHead: "支付订单"
      },
      tableData: [],
      // 获取订单详情列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          orderNumber: this.$route.query.orderNumber,
        },
      },
      total: 0,
      height: window.innerHeight - 256, //表格高度
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/supplierOrder",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      that.$http
        .post("/supplierOrder/save", that.supplierOrder)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/supplierOrder/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.supplierOrder = response.data.data;
              that.supplierOrder.memberName = decodeURI(
                that.supplierOrder.memberName
              );
              that.supplierOrder.receiver = decodeURI(
                that.supplierOrder.receiver
              );
            }
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/supplierOrderDetail/list", that.queryInfo)
        .then(function (response) {
          console.log('获取列表',response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
    // 监听排序
    sortChange() { },

    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    if (
      this.$route.query.orderNumber != undefined &&
      this.$route.query.orderNumber != ""
    ) {
      this.orderNumber = this.$route.query.orderNumber;
      this.getListData();
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    console.log("--------------------");
    console.log(this.$route.query.orderNumber);
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}

.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    margin: 5.5mm 3mm 10mm 3mm;
    /* this affects the margin in the printer settings */
  }

  // html {
  //   background-color: #ffffff;
  //   margin: 0; /* this affects the margin on the html before sending to printer */
  // }

  // body {
  //   // border: solid 1px blue;
  //   margin: 0mm 0mm 0mm 0mm; /* margin you want for the content */
  // }
}

h1 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clearfix {
  clear: both;
}

ul {
  list-style: none;
}

.print_container {
  padding: 20px;
  width: 188px;
}

.section1 {}

.section2 label {
  display: block;
}

.section3 label {
  display: block;
}

.section4 {}

.section4 .total label {
  display: block;
}

.section4 .other_fee {
  border-bottom: 1px solid #DADADA;
}

.section5 label {
  display: block;
}
</style>
